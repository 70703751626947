<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarStore.sidebarUnfoldable"
    :visible="sidebarStore.sidebarVisible"
    @visible-change="(event) => sidebarStore.updateSidebarVisible(event)"
  >
    <CSidebarBrand>
      <span>{{ appDetails.name }}</span>
      <CIcon
        custom-class-name="sidebar-brand-narrow"
        :icon="sygnet"
        :height="35"
        :unfoldable="sidebarStore.sidebarUnfoldable"
        :visible="sidebarStore.sidebarVisible"
      />
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="sidebarStore.toggleUnfoldable()"
    />
  </CSidebar>
</template>

<script>
import { useSidebarStore } from '@/stores/sidebar'
import AppSidebarNav from '@/components/AppSidebarNav.vue'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import { appDetailsVersion } from '@/services/appConfig'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const sidebarStore = useSidebarStore()
    return {
      logoNegative,
      sygnet,
      appDetails: appDetailsVersion,
      sidebarStore,
    }
  },
}
</script>
