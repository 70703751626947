import { defineStore } from 'pinia'
import { PROFILE_KEY } from '@/services/appConfig'

/**
 * Store user data into a dedicated store.
 */
export const useUserProfileStore = defineStore(PROFILE_KEY, {
  state: () => ({
    profile: {
      email: null,
      userId: 0,
      currency: null,
      orgCurrency: null, // Org currency
      defaultLanguage: null,
      initials: null,
      token: null,
    },
  }),
  actions: {
    setProfile(apiObject) {
      this.profile = { ...apiObject }
      // store the value to localstorage
      window.localStorage.setItem(PROFILE_KEY, JSON.stringify(this.profile))
    },
    restoreProfile() {
      const settings = window.localStorage.getItem(PROFILE_KEY)
      if (settings) {
        this.setProfile(JSON.parse(settings))
      }
    },
    clearProfile() {
      this.$reset()
      window.localStorage.removeItem(PROFILE_KEY)
    },
  },
  getters: {
    authHeader: (state) => {
      return {
        'X-Auth-Token': state.profile.token,
      }
    },
  },
})
