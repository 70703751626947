import axios from 'axios'
import { PROFILE_KEY } from './appConfig'

const API_URL = import.meta.env.VITE_APP_BACKEND_URL

/**
 * Save user profile
 * @param {Object} data
 */
function saveData(data) {
  window.localStorage.setItem(PROFILE_KEY, JSON.stringify(data))
}

/**
 * Get user data in LocalStorage
 * @param {string} key
 * @returns string | undefined
 */
function getData(key) {
  const data = window.localStorage.getItem(PROFILE_KEY)

  if (!data) {
    return false
  }

  const dataObj = JSON.parse(data)
  return dataObj[key]
}

/**
 * Clear user profile
 */
function clearProfile() {
  window.localStorage.removeItem(PROFILE_KEY)
}

function getAuthHeader() {
  return {
    'X-Auth-Token': getData('token'),
  }
}

/**
 * Check if the token is still active
 * If the token is expired, then the user is redirected to login
 *
 * @returns boolean
 */
async function checkToken() {
  const token = getData('token')

  if (token === false) {
    return false
  }

  const response = await axios
    .get(`${API_URL}/token`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Auth-Token': token,
      },
    })
    .then(() => true)
    .catch(() => false)

  return response
}

export { saveData, getData, clearProfile, checkToken, getAuthHeader }
