<template>
  <CDropdown>
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar size="md" color="primary" text-color="white">{{
        userProfileStore.profile.initials
      }}</CAvatar>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownItem href="#/user/me">
        <CIcon icon="cil-user" /> Settings
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem>
        <CButton @click="this.logout()">
          <CIcon icon="cil-lock-locked" /> Logout
        </CButton>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import axios from 'axios'
import { getAuthHeader, getData, clearProfile } from '@/services/appService'
import { useUserProfileStore } from '@/stores/userProfile'
import router from '@/router'
import { CButton } from '@coreui/vue'
export default {
  name: 'AppHeaderDropdownAccnt',
  data() {
    return {}
  },
  setup() {
    const userProfileStore = useUserProfileStore()
    return {
      itemsCount: 42,
      userProfileStore,
    }
  },
  methods: {
    /**
     * Logout method.
     * The token is send to beinvalidate.
     * It will be a better approach to send an id (user)
     * and invalidate the associate token
     */
    logout() {
      const token = getData('token')
      if (token) {
        axios
          .get(`${this.apiUrl}/token/invalidate/${token}`, {
            headers: getAuthHeader(),
          })
          .then(() => {
            // Delete token key
            clearProfile()
            // Clear store values
            this.userProfileStore.clearProfile()
            // redirect to login
            router.push('/login')
          })
          .catch((error) => console.log(error))
      }
    },
  },
  components: { CButton },
}
</script>
