export default {
  title: {
    help: 'Help',
    lost: "Oops! You're lost.",
    dashboard: 'Dashboard',
    iaf: 'Investment Approval Form',
    rs: 'Rentability Sheet',
    newrs: 'Rentability sheet creation',
    editrs: 'Rentability sheet edition',
    vehicles: 'Vehicles',
  },
  routes: {
    mainTitle: '',
    home: 'Home',
    dashboard: 'Dashboard',
    settings: 'Settings',
    appSettings: 'Application settings',
    users: 'Users',
    user: 'User',
    myProfile: 'Settings',
    workflows: 'Workflows',
    workflowsList: 'List',
    workflowsView: 'View',
    offers: 'Offers',
    offersList: 'List',
    offerView: 'View',
    offerCreation: 'Create',
    rs: 'Rentability sheets',
    rsList: 'Active sheets',
    rsCreation: 'Creation',
    rsEdition: 'Edition',
    iar: 'Invest authorization request',
    iarList: 'List',
    iarCreation: 'Creation',
    vehicles: 'Vehicles',
  },
  navMenu: {
    offers: 'Offers',
    rs: 'RS',
    workflows: 'Workflows',
    settings: 'Settings',
    vehicles: 'Vehicles',
  },
  message: {
    welcome: 'Loxea application',
    login: 'Sign In to your account',
    hello: 'Hello, world',
    forgotPassword: 'Forgot password',
    loginHelp: "You don't have an account yet ? Request for an access",
    lost: 'The page you are looking for was not found.',
    error: 'There is an error with your request',
    loginError: 'Incorrect username or password',
  },
  input: {
    username: 'Email',
    password: 'Password',
    login: 'Login',
    accessRequest: 'Access request',
    comment: 'Comment',
    description: 'Description',
    feature: 'Feature',
    amount: 'Amount',
  },
  iaf: {
    subsidiary: 'Subsidiary',
    region: 'Region',
    division: 'Division',
    iaf: 'IAF',
    iafFull: 'Investment Approval Form',
    entity: 'Entity',
    items: 'Items',
  },
  rentability: {
    labels: {
      assumptions: 'Assumptions',
      misc: 'Miscellaneous',
      disposal: 'Disposal',
      operations: 'Operations',
      synthesis: 'Synthesis',
    },
    sections: {
      contractAssumptions: 'Contract assumptions',
      adminAndOthers: 'Admin and others',
      disposal: 'Disposal',
      financing: 'Financing',
      maintenance: 'Maintenance',
      insurance: 'Insurance',
      carRelief: 'Car Relief',
      carReliefDisaster: 'Car Relief Disaster',
      driverServices: 'Driver services',
      contractManagement: 'Contract management',
      keyElements: 'Key elements of contract profitability',
      purchasePriceComission: 'Purchase Price Commission',
      vehicleDisposal: 'Vehicle disposal',
      fundingValues: 'Funding values',
      reliefCarBreakdown: 'Relief Car - Mechanical breakdown',
      reliefCarDamage: 'Reflief Car - Damage',
      assistance: 'Assistance',
      tracking: 'Tracking',
      technicalInspection: 'Technical Inspection',
      fileMargin: 'File margin',
      avisFees: 'Avis fees',
      monthlyOperation: 'Monthly operation',
      contractOperation: 'Contract operation',
      disposalContractDuration: 'DisposalContractDuration',
      totalContract: 'Total contract',
    },
    params: {
      brand: 'Brand',
      model: 'Model',
      version: 'Version',
      biPartiteLease: 'Bi partite - Lease',
      discountMargin: 'Discount / Margin',
      rate: 'Rate (%)',
      cityAssignment: 'City / Base of assignment of the vehicle',
      optionsAccessories: 'Options and accessories integrated into the PA',
      transactionPriceToBank: 'Transaction price to the bank',
      additionalAccessories: 'Additional non-amortized accessories',
      numberOfVehicules: 'Number of vehicles',
      capex: 'Capex',
      contractDuration: 'Contract duration',
      contractedHours: 'Contracted hours',
      contractedKm: 'Contracted Km',
      vehicleDepreciationPeriod: 'Vehicle depreciation period',
      buypackPA: 'Buypack %PA',
      buypackPS: 'Buypack %PS',
      calcBankRepurchase: 'Repurchase commitment to the bank - %PA hold',
      manualBankRepurchase: 'Repurchase commitment to the bank - %PA manual',
      monthlyRentalPrice: 'Monthly Rental Price',
      marginTotal: 'Margin Total',
      disposalMargin: 'Disposal Margin',
      standardPrice: 'Standard price',
      discountPrice: 'Discount price',
      purchasePrice: 'Purchase price',
      netBookValue: 'Net book value',
      extraCostPricePurchase: 'Extra cost price purchase',
      residualValueInRentCalculation: 'Residual value in rent calculation',
      repurchaseCommitmentToTheBank: 'Repurchase commitment to the bank',
      financingCost: 'Cost of financing',
      markUp: 'Mark up',
      maintenance: 'Maintenance',
      consoTiresBatteries: 'Consumption tires battery',
      securityMaintenanceCosts: 'Maintenance costs security',
      maintenanceCosts: 'Maintenance costs',
      reliefCar: 'Relief Car',
      tracking: 'Tracking',
      trackingDevice: 'Tracking device',
      trackingSubscription: 'Tracking subscription',
      telcoSubscription: 'Telco subscription',
      otherSubscription: 'Other subscription',
      technicalInspection: 'Technical Inspection',
      vignette: 'Vignette',
      parking: 'Parking',
      legalTaxDocument: 'Legal Tax document',
      miscAdminCosts: 'Miscellaneous admin costs',
      disposalValue: 'Disposal value',
      additionalReconditionningCosts: 'Additional reconditionning costs',
      resaleFinancingCustomer: 'Resale financing to the customer',
      monthlyTurnover: 'Monthly Turnover',
      monthlyCost: 'Monthly cost',
      debtFinancing: 'Debt Financing',
      grossMonthlyMargin: 'Gross Monthly Margin',
      excludingFinancialExpenses: 'Excluding Financial Expenses',
      netMonthlyMargin: 'net Monthly Margin',
      afterFinancialExpenses: 'After Financial Expenses',
      loxeaKmCost: 'Loxea cost per Km',
      customerKmCost: 'Client cost per Km',
      costOverContractLife: 'Cost over contract life',
      insurrance: 'Insurrance',
      annualCost: 'Annual Cost',
      breakdownCoverageRatio: 'Coverage ratio (replacement fleet size / customer fleet)',
      driverServices: 'Driver services',
      contractManagement: 'Contract management',
      fileMargin: 'File Margin',
      financialTurnover: 'Financial Turnover',
      monthlyCostExcludingFinancialCosts: 'Monthly cost excluding financial costs',
      monthlyCostWithFinancialExpenses: 'Monthly cost with financial expenses',
      servicesTurnover: 'Services Turnover',
      monthlyCostOfServices: 'Monthly Cost of Services',
      monthlyContributionPurchasingCommission: 'Monthly contribution Purchasing Commission',
      montlhyMargin: 'Montlhy margin',
      costsOfNonCapitalizedAccessories: 'Costs of non-capitalized accessories',
      avisFeesMonthly: 'Avis fees (Monthly)',
      fileMarginMonthly: 'File margin (monthly)',
      disposalCost: 'Disposal Cost',
      turnover: 'Turnover',
      costExcludingFinancialExpenses: 'Cost excluding financial expenses',
      totalGrossMargin: 'Total gross margin',
      costsIncludingFinancialExpenses: 'Cost including financial expenses',
      totalNetMargin: 'Total net margin',
      grossOperatingMargin: 'Gross operating margin',
      netOperatingMargin: 'Net operating margin',
    },
  },
}
