export default [
  {
    component: 'CNavItem',
    name: 'navMenu.offers',
    to: '#/offers',
    icon: 'cil-list-rich',
  },
  {
    component: 'CNavItem',
    name: 'navMenu.workflows',
    to: '#/workflows',
    icon: 'cil-check-circle',
  },
  {
    component: 'CNavItem',
    name: 'navMenu.settings',
    to: '#/settings',
    icon: 'cil-applications-settings',
  },
  {
    component: 'CNavItem',
    name: 'navMenu.vehicles',
    to: '#/vehicles',
    icon: 'cil-car-alt',
  },
]
