<template>
  <CSidebarNav>
    <li class="nav-title">Nav Title</li>
    <CNavItem v-for="item in menu" v-bind:key="item.name" v-bind:href="item.to">
      <CIcon customClassName="nav-icon" v-bind:icon="item.icon" />
      {{ $t(item.name) }}
    </CNavItem>
  </CSidebarNav>
</template>
<script>
import nav from '@/nav'
import { CNavItem, CSidebarNav } from '@coreui/vue'
export default {
  name: 'AppSideBarNav',
  data() {
    return {
      menu: nav,
    }
  },
  components: {
    CSidebarNav,
    CNavItem,
  },
}
</script>
