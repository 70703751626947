<template>
  <CFooter>
    <div>
      <span class="me-1">Env :</span>
      <span>{{ appEnv }}</span>
    </div>
    <div class="ms-auto">
      <span class="me-1">Version :</span>
      <span>{{ appVersion }}</span>
    </div>
  </CFooter>
</template>

<script>
import { appDetailsVersion } from '@/services/appConfig'
export default {
  name: 'AppFooter',
  setup() {
    return {
      appEnv: appDetailsVersion.env,
    }
  },
}
</script>
