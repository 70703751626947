import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import en from './locales/en'
import fr from './locales/fr'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample.vue'

const i18n = createI18n({
  legacy: false, // Keep it to false with composition api
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en: en,
    fr: fr,
  },
})

const pinia = createPinia()
const app = createApp(App)

/**
 * declare backend service url
 * Global properties can be called with this.propName in a vue context
 */
app.config.globalProperties.apiUrl = import.meta.env.VITE_APP_BACKEND_URL
app.config.globalProperties.appVersion = import.meta.env.VITE_APP_VERSION

app.use(pinia)
app.use(i18n)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.provide('apiUrl', import.meta.env.VITE_APP_BACKEND_URL)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

app.mount('#app')
